import { 
  SET_ACTIVE_DROPDOWN, SET_SEARCH, SET_SELECTED_RECORD_ID, SET_TABLE_VIEW, SET_FILTERS, 
  SET_SELECTED_RECORDS, ADD_ORIGINATOR_CONTACT_ID_TO_RECORD, UPDATE_CONTACT_ROLE, ADD_EMPTY_CONTACT_TO_RECORD, 
  REMOVE_CONTACT_FROM_RECORD, UPDATE_CONTACT_ID, SET_CONTACTS_AND_ROLES, UPDATE_SELECTED_AGENCY, SET_RECORDS_TABLE_PREV_STATE
} from "../actions/general";
import { modelTypes, statuses, twdbFloodPlanningRegionIds } from '../constants/formOptions';

const defaultState = {
  activeDropdown: null,
  showTableView: true,
  searchStr: '',
  selectedRecordId: null,
  selectedRecords: [],
  filters: {
    'region': twdbFloodPlanningRegionIds.reduce((accum, id) => {
      accum[id] = true;
      return accum;
    }, {}),
    'status': statuses.reduce((accum, id) => {
      accum[id] = true;
      return accum;
    }, {}),
    'modelType': modelTypes.reduce((accum, id) => {
      accum[id] = true;
      return accum;
    }, {}),
  },
  contactsByRecordId: {
    'new': ['']
  },
  contactRolesByRecordId: {
    'new': ['']
  },
  originatorContactsByRecordId: {},
  recordsTablePrevState: { pageIndex: 0 },
}

const generalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ACTIVE_DROPDOWN:
      return {
        ...state,
        activeDropdown: action.payload,
      };
    case SET_TABLE_VIEW:
      return {
        ...state,
        showTableView: action.payload,
      };
    case SET_SEARCH:
      return {
        ...state,
        searchStr: action.payload,
      };
    case SET_SELECTED_RECORD_ID:
      return {
        ...state,
        selectedRecordId: action.payload,
      };
    case SET_SELECTED_RECORDS:
      return {
        ...state,
        selectedRecords: action.payload,
      };
    case UPDATE_SELECTED_AGENCY:
      return {
        ...state,
        pwrd: action.pwrd,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterType]: {
            ...state.filters[action.payload.filterType],
            [action.payload.value]: action.payload.checked
          },
        },
      };
    case REMOVE_CONTACT_FROM_RECORD:
      const index = action.payload.index;
      let contacts = state.contactsByRecordId[action.payload.recordId] || [];
      let roles = state.contactRolesByRecordId[action.payload.recordId] || [];
      contacts.splice(index, 1);
      roles.splice(index, 1);
      return {
        ...state,
        contactsByRecordId: {
          ...state.contactsByRecordId,
          [action.payload.recordId]: contacts,
        },
        contactRolesByRecordId: {
          ...state.contactRolesByRecordId,
          [action.payload.recordId]: roles
        }
      }
    case ADD_EMPTY_CONTACT_TO_RECORD:
      return {
        ...state,
        contactsByRecordId: {
          ...state.contactsByRecordId,
          [action.payload.recordId]: [...(state.contactsByRecordId[action.payload.recordId] || []), '']
        },
        contactRolesByRecordId: {
          ...state.contactRolesByRecordId,
          [action.payload.recordId]: [...(state.contactRolesByRecordId[action.payload.recordId] || []), '']
        }
      }
    case UPDATE_CONTACT_ID:
      const originalContacts = state.contactsByRecordId[action.payload.recordId] || [];
      let newContacts = originalContacts;
      newContacts[action.payload.index] = action.payload.contactId
      return {
        ...state,
        contactsByRecordId: {
          ...state.contactsByRecordId,
          [action.payload.recordId]: newContacts
        }
      }
    case UPDATE_CONTACT_ROLE:
      const originalRoles = state.contactRolesByRecordId[action.payload.recordId] || [];
      let newRoles = originalRoles;
      newRoles[action.payload.index] = action.payload.contactRoleId
      return {
        ...state,
        contactRolesByRecordId: {
          ...state.contactRolesByRecordId,
          [action.payload.recordId]: newRoles
        }
      }
    case ADD_ORIGINATOR_CONTACT_ID_TO_RECORD:
      return {
        ...state,
        originatorContactsByRecordId: {
          ...state.originatorContactsByRecordId,
          [action.payload.recordId]: action.payload.contactId
        }
      }
    case SET_CONTACTS_AND_ROLES:
      return {
        ...state,
        contactsByRecordId: {
          [action.payload.recordId]: action.payload.contacts,
        },
        contactRolesByRecordId: {
          [action.payload.recordId]: action.payload.roles,
        }
      }
    case SET_RECORDS_TABLE_PREV_STATE:
      return {
        ...state,
        recordsTablePrevState: {
          ...state.recordsTablePrevState,
          ...action.payload
        }
      }
    default:
      return state;
  }
};

export default generalReducer;
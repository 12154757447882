import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Modal, ModalFooter, ModalHeader } from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import { historyModalSelector } from '../selectors/modals';
import { setHistoryModal } from '../actions/modals';
import { useRequest } from 'redux-query-react';
import { getHistoryQuery } from '../actions/queries';
import { recordHistorySelector } from '../selectors/entities';
import { selectedRecordIdSelector } from '../selectors/general';
import { Button, LinkLikeButton } from './Button';
import { GREY_LIGHT, ICE } from '../constants/cssVars';
import { metadataFieldInfo } from '../constants/metadataFields';
import { useCurrentUser } from '../hooks/useCurrentUser';

const HistoryItem = styled.div`
  background-color: ${ICE};
  padding: 10px;
  border-radius: 4px;
  > p {
    padding-top: 0px;
    margin-top: 0px;
  }
  margin-bottom: 10px;
`

const EntriesListWrapper = styled.div`
  max-height: 400px;
  overflow: auto;
`

const ListWrapper = styled.div`
  max-height: 400px;
  overflow: auto;
  margin: 0px -5px;
  border: 1px solid ${GREY_LIGHT};
  > div {
    border-right: 12px solid ${GREY_LIGHT};
    > div:last-child {
      border: none;
    }
  }
`

const MetadataRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3px 5px;
  > div {
  }
  font-size: 0.9em;
  > div:last-child {
    text-align: right;
    word-break: ${({ breakWords }) => breakWords ? 'break-all' : 'normal'};
    max-width: 60%;
  }
  border-bottom: 1px solid ${GREY_LIGHT};
`

const getFriendlyName = (name) => metadataFieldInfo[name] ? metadataFieldInfo[name].friendlyName : name

const fieldsToSkip = ['contacts', 'tdisDataIdentifier']

const getStringValue = value => Array.isArray(value) ? value.join(', ') : value

const ComparisonList = ({ currentMetadata, oldMetadata }) => {
  const fieldKeys = Object.keys(currentMetadata).filter(key => !fieldsToSkip.includes(key));
  return <ListWrapper>
    <div>
      {fieldKeys.map(key => {
        let oldValue = oldMetadata ? getStringValue(oldMetadata[key]) : null;
        let currentValueRaw = currentMetadata[key]
        if (key === 'timePeriodCovered') {
          currentValueRaw = `[${currentMetadata[key].map(dateArr => dateArr.map(date => moment(date).format('YYYY-MM-DD')))}]`;
          oldValue = oldValue ? `[${oldMetadata[key].map(dateArr => dateArr.map(date => moment(date).format('YYYY-MM-DD')))}]` : null;
        }
        const currentValue = getStringValue(currentValueRaw);
        const noChange = currentValue === oldValue;
        if (noChange) {
          return (
            <MetadataRow key={key} breakWords={key === 'spatialExtentShapefile'} bold={key === 'modelName'}>
              <div>{getFriendlyName(key)}</div>
              <div>{currentValue}</div>
            </MetadataRow>
          )
        }
        return (
          <div  key={key} >
            {oldValue != null &&
              <MetadataRow style={{ backgroundColor: '#F5B9B9' }} breakWords={key === 'spatialExtentShapefile'} bold={key === 'modelName'}>
                <div>{getFriendlyName(key)}</div>
                <div style={{ textDecoration: 'line-through', textDecorationColor: '#C13E3E'}}>{oldValue}</div>
              </MetadataRow>
            }
            <MetadataRow style={{ backgroundColor: '#BBF5B9' }} breakWords={key === 'spatialExtentShapefile'} bold={key === 'modelName'}>
              <div>{getFriendlyName(key)}</div>
              <div>{currentValue}</div>
            </MetadataRow>
          </div>
        )})}
    </div>
  </ListWrapper>
}

export const HistoryModal = () => {
  // Index of the history field to compare, show comparison screen if non-null
  const [comparisonIndex, setComparisonIndex] = useState(null);
  const showModal = useSelector(historyModalSelector);
  const selectedRecordId = useSelector(selectedRecordIdSelector);
  const { accessToken } = useCurrentUser();
  const dispatch = useDispatch();
  const [{ isFinished }] = useRequest(selectedRecordId ? getHistoryQuery(selectedRecordId, accessToken) : null);
  const history = useSelector(state => recordHistorySelector(state, selectedRecordId));

  if (!showModal || !selectedRecordId) {
    return null;
  }

  const onClose = () => {
    setComparisonIndex(null);
    dispatch(setHistoryModal(false));
  }

  if (comparisonIndex != null) {
    const historyObj = history[comparisonIndex];
    return (
      <Modal onClose={onClose}>
        <ModalHeader>Compare Changes</ModalHeader>  
        <ComparisonList currentMetadata={historyObj.currentMetadata} oldMetadata={historyObj.oldMetadata} />
        <ModalFooter>
          <Button onClick={() => setComparisonIndex(null)}>Back</Button>
        </ModalFooter>
      </Modal>
    )
  }
  
  return (
    <Modal onClose={onClose}>
      <ModalHeader>Metadata Record History</ModalHeader>  
      {Array.isArray(history) 
        ? <EntriesListWrapper>
            {history.map((historyObj, idx) => {
              return (<HistoryItem key={`hist_${idx}`}>
                <p>
                  {historyObj.action} by {historyObj.user} ({historyObj.username}) <br/>on {moment(historyObj.time).format('MMMM Do YYYY, h:mm a')}
                </p>
                <LinkLikeButton onClick={() => setComparisonIndex(idx)}>
                  Compare Changes
                </LinkLikeButton>
              </HistoryItem>)
            })}
          </EntriesListWrapper>
        : <div>Could not find history for given record.</div>}
    </Modal>
  )
}
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setActiveDropdown } from '../../actions/general';
import { GREY_MEDIUM, PRIMARY, PRIMARY_DARK, PRIMARY_LIGHT, SECONDARY, SHADOW_CRISP } from '../../constants/cssVars';
import { activeDropdownSelector } from '../../selectors/general';

const DropdownWrapper = styled.div`
  display: inline-block;
  margin-right: 10px;
  position: relative;
`

const DropdownButton = styled.button`
  font-size: 0.9em;
  border: 1px solid ${GREY_MEDIUM};
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 7px 15px;
  border-radius: 20px;
  color: ${PRIMARY};
  cursor: pointer;
  font-weight: 400;
  font-family: 'Open Sans';
  text-transform: none;
  &:hover {
    color: ${PRIMARY};
  }
`

const DropdownItems = styled.div`
  position: absolute;
  top: 2em;
  right: 0px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid grey;
  padding: 10px;
  box-shadow: ${SHADOW_CRISP};
  border-radius: 4px;
  min-width: 320px;
  font-size: 0.9em;
`

const ARROW_SIZE = 5;
const LilArrow = styled.div`
  width: 0; 
  height: 0; 
  border-top: ${ARROW_SIZE}px solid transparent;
  border-bottom: ${ARROW_SIZE}px solid transparent;
  border-left: ${ARROW_SIZE}px solid ${PRIMARY};
  transform: rotateZ(${({rotated}) => rotated ? 90 : 270}deg);
  margin-left: 10px;
  position: relative;
  top: 2px;
`

export const Dropdown = ({ buttonContent, dropdownId, children, style }) => {
  const dispatch = useDispatch();
  const activeDropdown = useSelector(activeDropdownSelector);
  const dropdownIsActive = activeDropdown !== dropdownId;

  const handleClick = (e) => {
    e.stopPropagation();
    if (activeDropdown !== dropdownId) {
      dispatch(setActiveDropdown(dropdownId))
    } else {
      dispatch(setActiveDropdown(null))
    }
  }

  return (
    <DropdownWrapper>
      <DropdownButton id={`${dropdownId}_btn`} onClick={handleClick}>
        {buttonContent} <LilArrow rotated={dropdownIsActive} />
      </DropdownButton>
      {activeDropdown === dropdownId && <DropdownItems style={style} id={dropdownId}>
        {children}
      </DropdownItems>}
    </DropdownWrapper>
  )
}
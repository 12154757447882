import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { useRequest } from 'redux-query-react';
import { MultiFieldLine, SelectField, TextField, CreatableInputOnly, MultiSelectField, DatepickerField, DatepickerRangeField, CheckboxField, ParagraphTextField } from './FormFields';
import { Button, InvertedButton, LinkLikeButton } from './Button'
import { validateMetadata } from '../utils/validators';
import { useGetRecordQuery, useSaveRecord } from '../hooks/messagesAndRequests';
import { metadataFieldInfo, metadataInit } from '../constants/metadataFields';
import { useHistory } from 'react-router-dom';
import { FOSSIL, GREY_DARK, MAIN_CONTENT_WIDTH } from '../constants/cssVars';
import { countyOptions, twdbFloodPlanningRegionIdOptions, cityOptions, getRegionOptionFromValue, modelTypeOptions, useSoftwareNameOptions, useAgencyOptions } from '../constants/formOptions';
import { Footer } from './Common';
import { useSelectedRecord } from '../hooks/useSelectedRecord';
import { getHucOptionsByIds, getHucOptionsByRegionId } from '../constants/hucInfo';
import { capitalizeFirstLetter } from '../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { contactsByRecordIdSelector, originatorContactsByRecordIdSelector } from '../selectors/general';
import { AddAContactField, ContactFieldAligner, ContactFormField, ContactRoleSelectField, ShowOriginatorTracker, useContactFieldErrors, useContactsArray, useConvertedContactFields } from './Contacts';
import { addEmptyContactToRecord, removeContactFromRecord, updateSelectedAgency} from '../actions/general';
import { getRecordQuery } from '../actions/queries';
import { toast } from 'react-toastify';
import { WaveLoader } from './Loaders';
import { useCurrentUser } from '../hooks/useCurrentUser';

const Content = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`

const InfoTextField = (props) => {
  return <TextField {...props} label={`${getFriendlyName(props.name)}${getRequired(props.name) ? '*' : ''}`} info={getDescription(props.name)} />
}

const InfoParagraphTextField = (props) => {
  return <ParagraphTextField {...props} label={`${getFriendlyName(props.name)}${getRequired(props.name) ? '*' : ''}`} info={getDescription(props.name)} />
}

const InfoSelectField = (props) => {
  return <SelectField {...props} label={`${getFriendlyName(props.name)}${getRequired(props.name) ? '*' : ''}`} info={getDescription(props.name)} />
}

const InfoMultiSelectField = (props) => {
  return <MultiSelectField {...props} label={`${getFriendlyName(props.name)}${getRequired(props.name) ? '*' : ''}`} info={getDescription(props.name)} />
}

const InfoDateField = (props) => {
  return <DatepickerField {...props} label={`${getFriendlyName(props.name)}${getRequired(props.name) ? '*' : ''}`} info={getDescription(props.name)} />
}

const InfoTimeDateRangeField = (props) => {
  return <DatepickerRangeField {...props} label={`${getFriendlyName(props.name)}${getRequired(props.name) ? '*' : ''}`} info={getDescription(props.name)} />
}

const InfoMultiTextInputCreateOnly = (props) => {
  return <CreatableInputOnly {...props} label={`${getFriendlyName(props.name)}${getRequired(props.name) ? '*' : ''}`} info={getDescription(props.name)} />
}

export const getContactName = name => {
  if (name.includes('contacts.')) {
    const contactArray = name.split('.');
    const contactString = `contact${capitalizeFirstLetter(contactArray[2])}`
    return contactString;
  }
  return name;
}

const getDescription = (name) => metadataFieldInfo[getContactName(name)].description
const getFriendlyName = (name) => metadataFieldInfo[getContactName(name)].friendlyName;
const getRequired = (name) => metadataFieldInfo[getContactName(name)].required

export const MetadataForm = () => {
  const [selectedRecord] = useSelectedRecord();
  return (
    <Content>
      <h2>{selectedRecord ? 'Edit' : 'Create new'} metadata record</h2>  
      <h3>Identifiers</h3>
      <MetadataFormContent />
    </Content>
  )
}

const MetadataFormContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRecord, recordId] = useSelectedRecord();
  const [saveObj, saveSubmitting] = useSaveRecord(recordId);
  const { accessToken } = useCurrentUser();
  const [{ isFinished: getRequestFinished }] = useRequest(accessToken && recordId !== 'new' ? getRecordQuery(recordId, accessToken) : null);
  const [pressedGenerate, setPressedGenerate] = useState(false);
  const initValues = selectedRecord ? { ...metadataInit, ...selectedRecord.currentMetadata } : metadataInit;
  // const [spatialExtent, setSpacialExtent] = useState(initValues.spatialExtentShapefile || null);
  const [softwareNameOptions, getNameOptionFromValue, softwareQueryFinished] = useSoftwareNameOptions();
  const [agencyOptions, getAgencyOptionFromValue, agencyQueryFinished] = useAgencyOptions();
  const contactsByRecordId = useSelector(contactsByRecordIdSelector);
  const originatorContactByRecordId = useSelector(originatorContactsByRecordIdSelector);
  const dbContactsField = useConvertedContactFields(recordId);
  const appFormattedContacts = useContactsArray(recordId);
  const contactIds = contactsByRecordId[recordId] || [];
  const contactErrors = useContactFieldErrors(recordId);
  // const [regionId, setRegionId] = useState(null);
  const originatorContactId = originatorContactByRecordId[recordId];

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  // const onFileChange = (e) => {
  //   submitGeodbFile(e.target.files[0], e.target.files[0].name, regionId || initValues.twdbFloodPlanningRegionID, accessToken);
  // }

  const noErrors = (errors) => {
    if (Object.keys(errors).length === 0 && Object.keys(contactErrors).length === 0) {
      return true;
    }
    const totalErrors = Object.keys(errors).length + Object.keys(contactErrors).length;
    toast.error(`Please fix ${totalErrors} error${totalErrors > 1 ? 's' : ''}.`)
    return false;
  }

  const submit = (values, errors) => {
    if (noErrors(errors)) {
      saveObj({ ...values, contacts: dbContactsField }, appFormattedContacts, (newId) => history.push(`/app/`));
    }
  }
  
  if ((recordId !== 'new' && !getRequestFinished) || !softwareQueryFinished || !agencyQueryFinished) {
    return <WaveLoader />
  }
  
  return (
      <Formik
        validate={validateMetadata(metadataFieldInfo)}
        enableReinitialize={true}
        onSubmit={() => null}
        initialValues={{
          ...initValues,
          showOriginatorContact: true,
        }}>
        {({ errors, touched, submitCount, values, setFieldValue }) => {
          // return error if field is touched
          const getError = (name) => (touched[name] || submitCount >= 1 || pressedGenerate) && errors[name];
          const getContactError = (name) => (touched[name] || submitCount >= 1 || pressedGenerate) && contactErrors[name];

          return (
            <Form onKeyDown={onKeyDown}>
            <MultiFieldLine columns="50% 50%">
              <InfoTextField name="modelName" error={getError("modelName")} />
              <InfoTextField name="modelIdentifier" error={getError("modelIdentifier")} />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              <InfoSelectField onChange={(newRegionId) => {
                setFieldValue('hydrologicUnitCode', [])
              }} optionSelector={getRegionOptionFromValue} name="twdbFloodPlanningRegionID" options={twdbFloodPlanningRegionIdOptions} error={getError("twdbFloodPlanningRegionID")} />
              <InfoMultiSelectField optionSelector={(ids) => getHucOptionsByIds(ids, values.twdbFloodPlanningRegionID)} options={getHucOptionsByRegionId(values.twdbFloodPlanningRegionID)} name="hydrologicUnitCode" error={getError("hydrologicUnitCode")} />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              <InfoMultiTextInputCreateOnly name="twdbFloodPlanningProjectID" error={getError("twdbFloodPlanningProjectID")} />
            </MultiFieldLine>

            <h3>Model details</h3>
            <MultiFieldLine columns="50% 50%">
              <InfoParagraphTextField name="modelDescription" error={getError("modelDescription")} />
              <InfoParagraphTextField name="modelPurpose" error={getError("modelPurpose")} />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              <InfoMultiSelectField name="modelType" options={modelTypeOptions} error={getError("modelType")} />
              <InfoDateField name="modelLastUpdatedDate" error={getError("modelLastUpdatedDate")} />              
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              <InfoDateField name="modelCreationDate" error={getError("modelCreationDate")} />
              <InfoMultiTextInputCreateOnly name="chainOfCustody" error={getError("chainOfCustody")} />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              <InfoMultiSelectField name="county" options={countyOptions} error={getError("county")} />
              <InfoMultiSelectField name="city" options={cityOptions} error={getError("city")} />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              {/* <FileUploadField name="spatialExtentShapefile" onFileChange={onFileChange} label={`${getFriendlyName('spatialExtentShapefile')}*`} info={getDescription('spatialExtentShapefile')} error={getError("spatialExtentShapefile")}>
                {spatialExtent && spatialExtent.length > 0 && <ShapefileWrapper>{spatialExtent}</ShapefileWrapper>}
              </FileUploadField> */}
              <InfoTimeDateRangeField name="timePeriodCovered" error={getError("timePeriodCovered")} />
              <InfoMultiTextInputCreateOnly name="documentationFilenames" error={getError("documentationFilenames")} />
            </MultiFieldLine>
            {/* <MultiFieldLine columns="50% 50%">
              <InfoTimeDateRangeField name="timePeriodCovered" error={getError("timePeriodCovered")} />
            </MultiFieldLine> */}

            <h3>Software details</h3>
            <MultiFieldLine columns="50% 50%">
              <InfoSelectField name="softwareName" optionSelector={getNameOptionFromValue} options={softwareNameOptions} error={getError("softwareName")} />
              <InfoTextField name="softwareVersion" error={getError("softwareVersion")} />
            </MultiFieldLine>

            <h3>Model Originator Information</h3>
            <MultiFieldLine columns="50% 50%">
              <InfoSelectField name="modelOriginatorAgencyId" short={true} optionSelector={getAgencyOptionFromValue} options={agencyOptions} onChange={(value) => dispatch(updateSelectedAgency(value))} error={getError("modelOriginatorAgencyId")} />
            </MultiFieldLine>
            <CheckboxField label="Add a contact associated with this agency" name="showOriginatorContact" error={getError("showOriginatorContact")} />
            {values.showOriginatorContact && <ContactFormField isOriginator={true} error={getError(`originator_contact`)} name={`originator-contact`} label={`Originator Contact`} contactId={originatorContactId} recordId={recordId} />}
            <ShowOriginatorTracker values={values} recordId={recordId} />

            <h3 style={{ marginTop: '40px' }}>Contacts</h3>
            <p style={{ fontSize: '0.95em', color: GREY_DARK, margin: '0px 0px 20px 0px'}}>The following contact fields will be used as the primary point(s) of contact for this model. At least one contact is required.</p>
            <ContactFieldAligner previousMetadata={selectedRecord ? selectedRecord.currentMetadata : null} recordId={recordId} />
            {contactIds.map((contactId, idx) => {
              const friendlyIndex = idx + 1;
              return <div key={`${idx}_contact`} style={{ backgroundColor: '#EBEEF0', border: `1px solid ${FOSSIL}`, padding: '15px', marginBottom: '15px'}}>
                  <ContactFormField error={getContactError(`contact_id_${idx}`)} index={idx} name={`contact-${friendlyIndex}`} label={`Contact`} contactId={contactId} recordId={recordId} />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '300px'}}>
                      <ContactRoleSelectField error={getContactError(`contact_role_${idx}`)} short={true} key={`${idx}_role_contact`} recordId={recordId} index={idx} name={`contact-role-${friendlyIndex}`} label={`Contact's Role For Model`}  />
                    </div>
                    {contactIds.length > 1 && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                      <LinkLikeButton type="button" onClick={() => dispatch(removeContactFromRecord(recordId, idx))}>
                        Remove
                      </LinkLikeButton>
                    </div>}
                  </div>
              </div>
            })}
            {contactIds.length > 0 ? <div style={{ textAlign: 'center' }}>
                <LinkLikeButton type="button" onClick={() => dispatch(addEmptyContactToRecord(recordId))}>
                  + Add another contact
                </LinkLikeButton>
            </div> : <AddAContactField onClick={() => dispatch(addEmptyContactToRecord(recordId))} />}

            <div style={{width: '100%', height: '120px'}}>
            </div>
            <Footer>
              <div>
                <div>
                  <InvertedButton type="button" onClick={() => history.goBack()}>
                    Back
                  </InvertedButton>
                  <div>
                    <Button onClick={() => {
                      submit(values, errors)
                    }} type="submit" style={{marginRight: '10px'}} isLoading={saveSubmitting}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Footer>
          </Form>
          )}}
      </Formik>
  )
}

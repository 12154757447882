import { useSelector } from "react-redux"
import { recordsSelector } from "../selectors/entities"
import { filterValuesSelector, searchStrSelector } from "../selectors/general";
import { stringIsEmptyOrNull } from "../utils/helpers";

// filter by search string, status, region, & model type
export const useFilteredRecords = () => {
  const records = useSelector(recordsSelector);
  const searchStr = useSelector(searchStrSelector);
  const regionFilters = useSelector((state) => filterValuesSelector(state, 'region'))
  const statusFilters = useSelector((state) => filterValuesSelector(state, 'status'))
  const typeFilters = useSelector((state) => filterValuesSelector(state, 'modelType'))

  // filter by filter dropdowns (region, status, model type)
  const categoryFilteredRecords = records.filter(obj => regionFilters[obj.currentMetadata.twdbFloodPlanningRegionID])
                                         .filter(obj => statusFilters[obj.statusInfo.status])
                                         .filter(obj => (obj.currentMetadata.modelType || []).some(type => typeFilters[type]));

  if (stringIsEmptyOrNull(searchStr)) {
    return categoryFilteredRecords;
  }
  // If search is active, filter on a match in model name or identifier
  return categoryFilteredRecords.filter(record => {
    return (record.currentMetadata.modelName && record.currentMetadata.modelName.toLowerCase().includes(searchStr.toLowerCase()))
        || (record.currentMetadata.modelIdentifier && record.currentMetadata.modelIdentifier.toLowerCase().includes(searchStr.toLowerCase()))
  });
}

// filter by pending status
export const usePendingRecords = () => {
  const records = useSelector(recordsSelector);
  return records.filter(record => record.statusInfo.status === 'pending');
}
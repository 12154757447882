import React from 'react';
import styled from 'styled-components';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Header } from './Header';
import { RejectModal } from './RejectModal';
import { HistoryModal } from './HistoryModal';
import { Flip, ToastContainer } from 'react-toastify';
import { Login } from './Login';
import { AllRecordsPage } from './AllRecordsPage';
import { MetadataForm } from './MetadataForm';
import { CloseActiveDropdown } from './general/CloseActiveDropdown';
import { useRequest } from 'redux-query-react';
import { getContactRolesQuery, getContactsQuery, getExistingCollectionsQuery } from '../actions/queries';
import { RecordOverview } from './RecordOverview';
import { usePendingRecords } from '../hooks/useFilteredRecords';
import SessionExpirationTimer from './general/SessionExpirationTimer';
import { ContactFormModal } from './ContactFormModal';
import { Settings } from './Settings';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { FilePreviewModal } from './FilePreviewModal';

const Wrapper = styled.div`
  width: 100%;
`;

const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`

const ReviewListRoutes = () => {
  const pendingRecords = usePendingRecords();

  return (
    <Switch>
      <Route path="/app/review-list/:recordId" exact={true} render={() => <RecordOverview reviewListMode={true} />} />
      <Redirect to={`/app/review-list/${pendingRecords[0].tdisDataIdentifier}`} />
    </Switch>
  )
}

const AppRoutes = () => {
  const { accessToken } = useCurrentUser();
  useRequest(accessToken ? getExistingCollectionsQuery(accessToken) : null);
  useRequest(accessToken ? getContactRolesQuery(accessToken) : null);
  useRequest(accessToken ? getContactsQuery(accessToken) : null);
  const pendingRecords = usePendingRecords();

  return (
    <AppWrapper>
      <Header />
      <Switch>
        <Route path="/app/" exact={true} render={() => <AllRecordsPage />} />
        {pendingRecords.length > 0 && <Route path="/app/review-list/" component={ReviewListRoutes} />}
        <Route path="/app/form/:recordId" component={MetadataForm} />
        <Route path="/app/view/:recordId" component={RecordOverview} />
        <Route path="/app/settings" exact={true} component={Settings} />
        {/* <Route path="/app/instructions/:recordId" component={Instructions} /> */}
        <Redirect to="/app/" />
      </Switch>
      <SessionExpirationTimer />
    </AppWrapper>
  )
}

export const Main = () => {
  const { accessToken } = useCurrentUser();
  return (
    <Wrapper>
      <RejectModal />
      <HistoryModal />
      <ContactFormModal />
      <FilePreviewModal />
      <ToastContainer autoClose={3000} position="bottom-right" transition={Flip} hideProgressBar />
      <CloseActiveDropdown />
      <main>
        <Switch>
          <Route path="/login" exact={true} component={Login} />
          {accessToken && <Route path="/app" component={AppRoutes} />}
          <Redirect to="/login" />
        </Switch>
      </main>
    </Wrapper>
  )
}
import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { BG_LIGHTER, GREY_DARK, GREY_LIGHT, GREY_LIGHTER, GREY_MEDIUM, GREY_MEDIUM_LIGHT, ICE, MAIN_CONTENT_WIDTH, ONYX, PRIMARY, PRIMARY_DARK, PRIMARY_LIGHT, SECONDARY, SHADOW_MEDIUM } from '../constants/cssVars';

export const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ color }) => color || SECONDARY};
`;

export const StyledInternalLink = styled(HashLink)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ color }) => color || SECONDARY};
`;

export const TextLink = ({ to, color = PRIMARY_LIGHT, children }) => {
  return (
    <StyledLink color={color} target="_blank" rel="noreferrer noopener" href={to}>
      {children}
    </StyledLink>
  )
}

export const StyledInput = styled.input`
  border: 1px solid ${GREY_MEDIUM};
  border-radius: 20px;
  padding: 5px 15px !important;
  &:focus {
    outline: none !important;
    border: 1px solid #1591BF;
    border-radius: 20px;
    box-shadow: 0 0 2px 2px rgb(93, 196, 255);
  }
`

export const OuterWrapper = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  border-radius: 8px;
  box-shadow: ${SHADOW_MEDIUM};
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`

export const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  border-radius: 8px;
`

export const Table = styled.table`
  background-color: #fff;
  border-collapse: collapse;
  border-radius: 8px;
`

export const TableHeader = styled.th`
  text-align: left;
  color: ${PRIMARY};
  border-bottom: 1px solid ${GREY_MEDIUM_LIGHT};
  border-right: 1px solid ${GREY_LIGHT};
  padding: 10px;
  font-size: 0.8em;
`

export const TableRow = styled.tr`
  ${({ clickable }) => clickable ? `
    &:hover {
      > td {
        transition: background-color 0.1s linear;
        background-color: ${ICE};
        cursor: pointer;
      }
      > td:first-child {
        cursor: default;
      }
    }
    border-bottom: 1px solid ${GREY_LIGHT};
  ` : ''}
`

export const TableData = styled.td`
  background-color: #fff;
  max-height: 100px;
  max-width: 250px;
  border: 1px solid ${GREY_LIGHT};
  font-size: 0.8em;
  padding: 10px 10px;
  color: ${PRIMARY};
  overflow: auto;
  div {
    overflow: auto;
  }
`

export const TableHeaderInner = styled.div`
  display: flex;
  align-items: center;
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div:first-child {
    margin-right: auto;
  }
  > div:last-child {
    margin-left: 10px;
  }
  color: ${GREY_DARK};
  border-radius: 8px 8px 0px 0px;
`;

export const TableFooter = styled.div`
  background-color: ${GREY_LIGHTER};
  color: ${GREY_DARK};
  border-radius: 0px 8px 8px 8px;
  > div {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Footer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  > div {
    display: flex;
    padding: 10px;
    justify-content: center;
    border-top: 1px solid rgba(200, 209, 211, 0.5);
    background-color: rgba(255,255,255,0.8);
    > div {
      display: flex;
      width: 830px;
      justify-content: space-between;
      align-items: center;
    }
  }
`
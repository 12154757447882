import React, { useState } from 'react'
import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'
import {
  SHADOW_NONE,
  SHADOW_MENU,
  BG_DARKER,
  SHADOW_DARK,
  BG_PRIMARY,
  PRIMARY,
  BG_LIGHTER,
  MAX_DESKTOP_WIDTH,
  SECONDARY,
  FOSSIL,
  ICE,
  SLATE,
  RAIN,
} from '../constants/cssVars.js'
import menuImg from '../assets/icons/menu.svg'
import closeImg from '../assets/icons/menu-close.svg'
import { useLogout } from '../hooks/useLogout'
import { TextLink } from './Common.js'

const MENU_WIDTH = 350

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  z-index: 1000;
`

// shifts menu contents slightly left
const OffsetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: ${MAX_DESKTOP_WIDTH}px) {
    font-size: 18px;
  }
`

const MenuButton = styled.button`
  background-color: #526d80;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;
  &:hover {
    background-color: #607d91;
  }
`

const MenuImg = styled.img`
  width: 35px;
  height: 20px;
`

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
`

const ClickableWrapper = styled.button`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0px;
  background-color: ${PRIMARY};
  font-size: 18px;
`

const CloseText = styled.a`
  color: ${BG_PRIMARY};
  font-weight: 600;
  text-decoration: underline;
`

const CloseImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`

const MenuNav = styled.nav`
  background-color: ${PRIMARY};
  color: ${BG_PRIMARY};
  margin: 0px;
  height: 100vh;
  width: ${MENU_WIDTH}px;
  border-left: 3px solid ${SECONDARY};
  position: fixed;
  z-index: 1;
  top: 0;
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  right: ${({ active }) => (active ? 0 : -MENU_WIDTH)}px;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: ${({ active }) => (active ? SHADOW_MENU : SHADOW_NONE)};
`

const MenuList = styled.div`
  margin: 0px;
  padding: 10px 30px;
  font-size: 20px;
  > a,
  button.like-text {
    font-weight: 700;
    font-size: 20px;
    margin: 15px 0px;
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
    transition: color 0.2s ease;
    color: ${BG_LIGHTER};
    text-decoration: none;
    display: block;
    &:hover {
      font-color: ${BG_DARKER};
    }
  }
`

const SlideOutMenu = ({ active, setActive }) => {
  const close = () => setActive(false)
  const logout = useLogout()

  return (
    <OuterWrapper active={active} onClick={close}>
      <MenuNav
        role="navigation"
        active={active}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseWrapper>
          <ClickableWrapper tabIndex="0" onClick={close}>
            <CloseText>close</CloseText>
            <CloseImg src={closeImg} alt="" />
          </ClickableWrapper>
        </CloseWrapper>
        <MenuList>
          <Link onClick={close} tabIndex="0" to="/app/">
            Home
          </Link>
          <Link onClick={close} tabIndex="0" to="/app/settings">
            Settings
          </Link>
          <TextLink to="https://ms2adminuserguide.z21.web.core.windows.net">
            Documentation
          </TextLink>
          {/* <Link onClick={close} tabIndex="0" to='/app/contacts'>Manage Contacts</Link> */}
          <hr style={{ borderColor: '#fff' }} />
          <button className="like-text" onClick={logout} tabIndex="0">
            Logout
          </button>
        </MenuList>
      </MenuNav>
    </OuterWrapper>
  )
}

export const Menu = (props) => {
  const [menuActive, setMenuActive] = useState(false)
  return (
    <>
      <MenuButton onClick={() => setMenuActive(true)}>
        <OffsetWrapper>
          <MenuImg src={menuImg} alt="menu" ariaLabel="menu" />
        </OffsetWrapper>
      </MenuButton>
      <SlideOutMenu active={menuActive} setActive={setMenuActive} {...props} />
    </>
  )
}

import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ICE, SHADOW_CRISP, SLATE } from '../constants/cssVars';
import { Button, InvertedButton, LinkLikeButton } from './Button';
import { useSelector } from 'react-redux';
import { messagesByIdSelector } from '../selectors/entities';
import { getMessagesQuery } from '../actions/queries';
import { useRequest } from 'redux-query-react';
import { WaveLoader } from './Loaders';
import { useSendMessageQuery } from '../hooks/messagesAndRequests';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { QUERY_REFRESH_INTERVAL_MS } from '../constants/general';
import { useInterval } from '../hooks/useInterval';


const MessagesArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  > div:first-child {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${ICE};
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 2px;
  }
`

const MessageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.85em;
  color: ${SLATE};
  > div:first-child {
    font-weight: 600;
    font-size: 0.97em;
  }
  > div:last-child {
    font-style: italic;
  }
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ isMessageFromCurrentUser }) => isMessageFromCurrentUser ? '#d8e4ec' : '#fff'};
  border: 1px solid ${SLATE};
  padding: 15px;
  padding-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  align-self: ${({ isMessageFromCurrentUser }) => isMessageFromCurrentUser ? 'flex-end' : 'flex-start'};
`

const MessageCreate = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-end;
  background-color: #d8e4ec;
  box-shadow: ${SHADOW_CRISP};
  padding: 15px;
  border-radius: 2px;
  margin-bottom: 10px;
  > textarea {
    border-radius: 0px;
    border: 1px solid ${SLATE};
    margin-bottom: 10px;
  }
  > div {
    display: flex;
    justify-content: flex-end;
  }
`

const MAX_INIT_MESSAGES = 3;

// Messages section for user to send and view messages from a TWDB admin
export const Messages = ({ recordId }) => {
  const { accessToken, username } = useCurrentUser();
  const [{ isFinished }, refresh] = useRequest(getMessagesQuery(recordId, accessToken));
  const messagesById = useSelector(messagesByIdSelector);
  const messages = messagesById[recordId] || [];
  const [showAllMessages, setShowAllMessages] = useState(false);
  const [showMessageInput, setShowMessageInput] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [submit, submitting] = useSendMessageQuery(() => {
    setNewMessage('');
    setShowMessageInput(false);
  });

  // Occassionally refresh our messages
  useInterval(() => {
    refresh();
  }, QUERY_REFRESH_INTERVAL_MS);

  if (!isFinished) {
    return <WaveLoader />
  }

  let messagesToDisplay = messages;
  if (!showAllMessages && Array.isArray(messages) && messages.length > MAX_INIT_MESSAGES) {
    messagesToDisplay = messages.slice(Math.max(messages.length - MAX_INIT_MESSAGES, 0))
  }

  return (
    <MessagesArea>
      <div>
        {messages.length > MAX_INIT_MESSAGES && <LinkLikeButton style={{ color: SLATE, fontSize: '0.95em', marginBottom: '5px' }} onClick={() => setShowAllMessages(!showAllMessages)}>
          {showAllMessages ? 'Show less' : `Show all`} messages
        </LinkLikeButton>}
        {Array.isArray(messagesToDisplay) && messagesToDisplay.length > 0 && messagesToDisplay.map((message, index) => {
          return <Message isMessageFromCurrentUser={message.username === username ? 1 : 0} key={`message_${index}`}>
            <MessageHeader>
              <div>
                {message.username}
              </div>
              <div>
                Sent on {moment(message.date).format('MMMM Do YYYY, h:mm a')}
              </div>
            </MessageHeader>
            <div>
              {message.content}
            </div>
          </Message>
        })}
        {messages.length < 1 && <div style={{ fontStyle: 'italic', textAlign: 'center', marginBottom: '5px'}}>
          No messages at this time. Click below to send a message to the vendor.
        </div>}
      </div>
      {!showMessageInput && <LinkLikeButton onClick={() => setShowMessageInput(true)}>Send a message to the vendor</LinkLikeButton>}
      {showMessageInput && (
        <MessageCreate>
          <textarea onChange={(e) => setNewMessage(e.target.value)} value={newMessage} placeholder="Your message here ..." /> 
          <div>
            <InvertedButton onClick={() => setShowMessageInput(false)}>
              Cancel
            </InvertedButton>
            <Button isLoading={submitting} onClick={() => submit({ msg_content: newMessage, tdis_identifier: recordId, username })}>
              Send Message
            </Button>
          </div>
        </MessageCreate>
      )}
    </MessagesArea> 
  )
}
import React from 'react';
import { Formik, Form } from 'formik';
import { Modal, ModalHeader, ModalFooter } from './Modal';
import { CheckboxGroup, OptionsList, ParagraphTextField, RadioButtonField, SelectField, TextField } from './FormFields';
import { Button } from './Button'
import { useDispatch, useSelector } from 'react-redux';
import { rejectModalSelector } from '../selectors/modals';
import { validateRejectForm } from '../utils/validators';
import { setRejectModal } from '../actions/modals';
import { rejectionReasonOptions } from '../constants/formOptions';
import { useSetStatusQuery } from '../hooks/messagesAndRequests';
import { mutateAsync } from 'redux-query';
import { getNotificationsQuery, getRecordQuery } from '../actions/queries';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { statusIsGood } from '../utils/helpers';
import { toast } from 'react-toastify';
import { selectedRecordIdSelector } from '../selectors/general';

export const RejectModal = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(rejectModalSelector);
  const handleClose = () => dispatch(setRejectModal(false));
  const recordId = useSelector(selectedRecordIdSelector);
  const { accessToken, username } = useCurrentUser();
  const [submit, submitting] = useSetStatusQuery(() => {
    dispatch(mutateAsync(getRecordQuery(recordId, accessToken))).then(({ status }) => {
      if (!statusIsGood(status)) {
        toast.error('An error occurred fetching model')
      }
      dispatch(mutateAsync(getNotificationsQuery(recordId, accessToken))).then(() => {
        handleClose();
      })
    })
  });

  const handleSubmit = (values) => {
    const commentStr = `Primary reason(s): \n- ${values.reasonForRejection.join('\n- ')}.\nAdditional details: ${values.details}`
    submit({ 
      tdisDataIdentifier: recordId, 
      status: 'rejected',
      comments: commentStr,
      username
    })
  }

  if (!showModal) {
    return null;
  }
  
  return (
    <Modal onClose={handleClose}>
      <Formik
        validate={validateRejectForm}
        initialValues={{ 
          details: '',
          reasonForRejection: [],
          tdisDataIdentifier: recordId,
        }}
        onSubmit={handleSubmit}>
        {({ errors, touched, submitCount }) => {
          // return error if field is touched
          const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
          return (
            <Form title="reject-upload">
              <ModalHeader>Reject Upload</ModalHeader>  
              <CheckboxGroup  name="reasonForRejection" labelId="reasonForRejectionLabel"  label="What is the reason for this rejection?*"
                    error={getError('reasonForRejection')} checkboxOptions={rejectionReasonOptions}
                    checkboxStyle={{ marginRight: '20px'}} />
              <ParagraphTextField name="details" label="Additional details*" error={getError("details")} />
              <ModalFooter>
                <Button type="submit" isLoading={submitting} style={{ minWidth: '260px'}}>
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          )}}
      </Formik>
    </Modal>
  )
}

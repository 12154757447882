import React, { useState } from 'react';
import styled from 'styled-components';
import { useRequest } from 'redux-query-react';
import { Formik, Form } from 'formik';
import { MultiFieldLine, SelectField, TextField } from './FormFields';
import { Button, LinkLikeButton } from './Button'
import { validateContact } from '../utils/validators';
import { contactModalInfoSelector, contactModalSelector } from '../selectors/modals';
import { setContactModal } from '../actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader } from './Modal';
import { useAddContactQuery, useUpdateContactQuery } from '../hooks/messagesAndRequests';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { updateContactId, updateOriginatorContactId } from '../actions/general';
import { useAgencyOptions } from '../constants/formOptions';
import { contactsByIdSelector } from '../selectors/entities';
import { ClickableContactTile } from './Contacts';
import { getContactByIdQuery } from '../actions/queries';
import { WaveLoader } from './Loaders';
import { GREY_DARK, GREY_MEDIUM_LIGHT } from '../constants/cssVars';
import { stringIsEmptyOrNull } from '../utils/helpers';
import { selectedAgencyIdSelector } from '../selectors/general';

const TextWrapper = styled.div`
  font-size: 0.95em;
`

const ContactForm = ({ onClose, editingPersonalContact }) => {
  const [agencyOptions, getAgencyOptionFromValue] = useAgencyOptions();
  const { contactId, isOriginator, recordId, index } = useSelector(contactModalInfoSelector);
  const { accessToken } = useCurrentUser();
  const [{ isFinished }] = useRequest(!stringIsEmptyOrNull(contactId) ? getContactByIdQuery(contactId, accessToken) : null);
  const contactsById = useSelector(contactsByIdSelector);
  const originatorAgencyId = useSelector(selectedAgencyIdSelector);
  const contact = contactsById[contactId];
  const isCreatingNew = contactId == null || contactId === '';
  const { id } = useCurrentUser();
  const dispatch = useDispatch();


  const [addNewContact, addingNewContact] = useAddContactQuery((body) => {
    // TODO: Use response to populate; for now just mock an id
    const newContactId = body && Array.isArray(body.features) && body.features.length > 0 ? body.features[0].properties.tdis_contact_id : '';
    if (isOriginator) {
      dispatch(updateOriginatorContactId(newContactId, recordId));
    } else {
      dispatch(updateContactId(newContactId, recordId, index));
    }
    onClose();
  })

  const [updateContact, updatingContact] = useUpdateContactQuery(onClose)

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const onSubmit = (values) => {
    let accepted = isCreatingNew;
    if (!isCreatingNew) {
      accepted = window.confirm("Are you sure you want to update this contact? Please be certain that this is the correct contact information for this email, as it will update this contact across our system.")
    }
    if (accepted) {
      const submit = isCreatingNew ? addNewContact : updateContact
      const formattedValues = isCreatingNew ? {
        userId: id, 
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        work_phone: values.phoneNumber,
        cell_phone: values.phoneNumber,
        agency: values.agency,
        comments: ''
      } : {
        userId: id, 
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        work_phone: values.phoneNumber,
        cell_phone: values.phoneNumber,
        agency: values.agency,
        comments: '',
        contact_id: contact.contact_id,
        tdis_contact_id: contact.contact_id,
      }
      submit(formattedValues);
    }
  }

  const setContactModalProps = { showModal: true, contactId, isOriginator, recordId, index, selectExisting: false }
  
  if (!stringIsEmptyOrNull(contactId) && !isFinished) {
    return <WaveLoader />
  }

  return <>
      <ModalHeader>{isCreatingNew ? 'Create New' : 'Edit'} Contact</ModalHeader>  
      <div>
        <Formik
          validate={validateContact(isOriginator, contactsById, isCreatingNew)}
          onSubmit={onSubmit}
          enableReinitialize={true}
          initialValues={
            isCreatingNew ? {
            firstName: '',
            lastName: '',
            agency: originatorAgencyId || '',
            email: '',
            phoneNumber: ''
          } : {
            ...(contact || {})
          }}>
          {({ errors, touched, submitCount }) => {
            // return error if field is touched
            const getError = (name) => (touched[name] || submitCount >= 1) && errors[name];
            

            return (
              <Form onKeyDown={onKeyDown}>
                {!isCreatingNew && <TextWrapper>
                  <p>Editing a contact will update its information across all records, including ones you may not own. <strong style={{ color: GREY_DARK }}>Please be certain you have the most up to date contact information before submitting.</strong></p>
                  <p style={{ marginTop: '10px', marginBottom: '20px' }}>Don't want to edit? Consider{` `}
                    <LinkLikeButton bodyFont={true} style={{ color: GREY_DARK }} onClick={() => dispatch(setContactModal({ ...setContactModalProps, selectExisting: true }))}>
                      switching to a different contact
                    </LinkLikeButton> or <LinkLikeButton bodyFont={true} style={{ color: GREY_DARK }} onClick={() => dispatch(setContactModal({ ...setContactModalProps, contactId: null }))}>
                      creating a new contact.
                    </LinkLikeButton>
                  </p>
                </TextWrapper>}
                <MultiFieldLine columns="50% 50%">
                  <TextField name={`firstName`} label="First Name*" error={getError(`firstName`)} />
                  <TextField name={`lastName`} label="Last Name*" error={getError(`lastName`)} />
                </MultiFieldLine>
                <MultiFieldLine columns="50% 50%">
                  <TextField disabled={!isCreatingNew} name={`email`} label={'Email*'} error={getError(`email`)} />
                  <TextField name={`phoneNumber`} label="Phone number*" error={getError(`phoneNumber`)} />
                </MultiFieldLine>
                <MultiFieldLine columns="50% 50%">
                  <SelectField options={agencyOptions} optionSelector={getAgencyOptionFromValue} name={`agency`} label="Affiliation*" error={getError(`agency`)} />
                </MultiFieldLine>
                <div style={{ textAlign: 'right', marginTop: '5px'}}>
                  <Button type="submit" isLoading={addingNewContact || updatingContact}>
                    {isCreatingNew ? 'Create' : 'Save'}
                  </Button>
                </div>
              </Form>
            )}}
        </Formik>
      </div>
    </>
}

const SelectContactForm = ({ onClose }) => {
  const { isOriginator, recordId, index } = useSelector(contactModalInfoSelector);
  const [search, setSearch] = useState('');
  const contactsById = useSelector(contactsByIdSelector);
  const contacts = Object.keys(contactsById).map(key => contactsById[key]).filter(val => val != null && Object.keys(val).length > 0);
  const setContactModalProps = { showModal: true, contactId: null, isOriginator, recordId, index, selectExisting: false }

  const filteredContacts = contacts.filter(contact => {
    const name = `${contact.firstName} ${contact.lastName}`.toLowerCase();
    const email = contact.email.toLowerCase();
    const searchStr = search.toLowerCase();
    if (email.includes(searchStr) || name.includes(searchStr)) {
      return true;
    }
    return false;
  })

  const dispatch = useDispatch();

  const submit = (contactId) => {
    if (isOriginator) {
      dispatch(updateOriginatorContactId(contactId, recordId));
    } else {
      dispatch(updateContactId(contactId, recordId, index));
    }
  }

  const onSubmit = (contactId) => {
    submit(contactId);
    onClose();
  }

  return <>
      <ModalHeader>Select Contact</ModalHeader>  
      <div>
        <div style={{ paddingBottom: '10px', borderBottom: `1px solid ${GREY_MEDIUM_LIGHT}` }}>
          <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search by name or email" />
        </div>
        {search && search.length > 0 ? 
          filteredContacts.length > 0 ? 
          <div style={{ height: '400px', overflow: 'auto', borderBottom: `1px solid ${GREY_MEDIUM_LIGHT}` }}>
            {filteredContacts.map((contact, idx) => {
              return <ClickableContactTile contact={contact} onClick={() => onSubmit(contact.id)} key={`clickable_contact_${idx}`} />
            })}
          </div>
        : <div style={{ height: '370px', paddingTop: '30px', textAlign: 'center', fontStyle: 'italic', color: GREY_DARK}}>
            No contacts matching this search information found. Would you like to <LinkLikeButton bodyFont={true} style={{ color: GREY_DARK, fontStyle: 'italic', }} onClick={() => dispatch(setContactModal(setContactModalProps))}>
              create a new contact
            </LinkLikeButton>?
          </div>
        : <div style={{ height: '370px', paddingTop: '30px', textAlign: 'center', fontStyle: 'italic', color: GREY_DARK}}>
            Start typing a name or email to search existing contacts
          </div>}
      </div>
    </>
}

export const ContactFormModal = () => {
  const { selectExisting } = useSelector(contactModalInfoSelector);
  const dispatch = useDispatch();

  const onClose = () => dispatch(setContactModal({ showModal: false }))
  const showModal = useSelector(contactModalSelector);

  if (!showModal) {
    return null;
  }

  return (
    <Modal onClose={onClose} style={{ overflow: selectExisting ? 'auto' : 'visible' }}>
      {selectExisting ? <SelectContactForm onClose={onClose} /> : <ContactForm onClose={onClose} />}
    </Modal>
  )
}
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinkLikeButton } from './Button';
import { MAIN_CONTENT_WIDTH, GREY_LIGHTER, GREY_MEDIUM, PRIMARY, RAIN, NOTICE, WIDE_CONTENT_WIDTH } from '../constants/cssVars';
import searchIcon from '../assets/icons/search.svg'
import { useHistory } from 'react-router';
import { Dropdown } from './general/Dropdown';
import cardIcon from '../assets/icons/hierarchy.svg';
import tableIcon from '../assets/icons/table.svg';
import cardIconWhite from '../assets/icons/hierarchy_white.svg';
import tableIconWhite from '../assets/icons/table_white.svg';
import { searchStrSelector, showTableViewSelector, selectedRecordsSelector } from '../selectors/general';
import { setSearch, setTableView } from '../actions/general';
import { usePendingRecords } from '../hooks/useFilteredRecords';
import { modelTypeOptions, statusOptions, twdbFloodPlanningRegionIdOptions } from '../constants/formOptions';
import { FilterCheckboxGroup } from './FormFields';
import { WaveLoader } from './Loaders';
import { getRecordsQuery } from '../actions/queries';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useRequest } from 'redux-query-react';
import { useAcceptRecordsQuery } from '../hooks/messagesAndRequests';
import { RecordsTable } from './RecordsTable';
import { RecordsHierarchy } from './RecordsHierarchy';

const SearchWrapper = styled.div`
  position: relative;
  width: 260px;
  font-size: 0.9em;
  > img {
    position: absolute;
    top: 10px;
    right: 11px;
    width: 20px;
    height: 20px;
  }
  > input {
    padding: 7px;
    padding-left: 15px;
    padding-right: 35px !important;
  }
`

const SearchAndNew = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const HierarchyButton = styled.button`
  border-radius: 0px 30px 30px 0px;
  padding: 5px 10px;
  padding-right: 15px;
  background-color: ${({active}) => active ? PRIMARY : '#fff'};
  border: 1px solid ${GREY_MEDIUM};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
  > img {
    width: 30px;
    height: 30px;
  }
  &:hover {
    background-color: ${({active}) => active ? PRIMARY : GREY_LIGHTER};
  }
`

const TableButton = styled(HierarchyButton)`
  border-radius: 30px 0px 0px 30px;
  padding-left: 15px;
  padding-right: 10px;
  border-right: 0px;
`

const Header = styled.h3`
  margin: 0px;
`

const TopContent = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`

const PendingItemsNotice = styled.div`
  background-color: ${NOTICE};
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  color: ${RAIN};
  display: flex;
  align-items: center;
  flex-direction: column;
  button {
    margin-top: 10px;
    font-size: 1em;
    padding: 8px 15px;
    min-width: 100px;
  }
`

const SelectionBlurb = styled.div`
  background-color: #B8CAD6;
  padding: 12px;
  font-size: 0.9em;
  padding-bottom: 20px;
  margin: -${(WIDE_CONTENT_WIDTH - MAIN_CONTENT_WIDTH)/2}px;
  margin-bottom: -10px;
  margin-top: -5px;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
`

// All records display, in either a table or hierarchy view
export const AllRecordsPage = () => {
  const { username, accessToken } = useCurrentUser();
  const [{ isFinished: recordsQueryFinished }, refresh] = useRequest(accessToken ? getRecordsQuery(username, accessToken) : null);
  const search = useSelector(searchStrSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const pendingRecords = usePendingRecords();
  const showTableView = useSelector(showTableViewSelector);
  const selectedRecords = useSelector(selectedRecordsSelector);
  const selectedPendingRecords = selectedRecords.filter(record => record.status === 'pending');
  const [acceptRecords, acceptingRecords] = useAcceptRecordsQuery(refresh);

  const handleAcceptRecords = () => {
    acceptRecords({
      tdis_identifiers: selectedPendingRecords.map(record => record.tdisDataIdentifier),
      username,
      comments: 'Multiple accept'
    })
  }
  
  return (
    <>
      <TopContent>
        {pendingRecords.length > 0 
          ? <PendingItemsNotice>
              {pendingRecords.length > 1 ?
                <div>
                  There are <b>{pendingRecords.length} records</b> pending that need to be reviewed.
                </div>
              : <div>
                  There is <b>{pendingRecords.length} record</b> pending that needs to be reviewed.
                </div>}
              <Button onClick={() => history.push('/app/review-list')}>Review now</Button> 
            </PendingItemsNotice> 
          : <PendingItemsNotice>
              {recordsQueryFinished ? 'There are currently no pending records.' : <WaveLoader />}
            </PendingItemsNotice>}
        <SearchAndNew>
          <Header>
            All Uploaded Records
          </Header>
        </SearchAndNew>
        <SearchAndNew>
          <div>
            <TableButton id="tableFormatButton" active={showTableView} onClick={() => dispatch(setTableView(true))}>
              <img src={showTableView ? tableIconWhite : tableIcon} alt="table format" />
            </TableButton>
            <HierarchyButton id="hierarchyFormatButton" active={!showTableView} onClick={() => dispatch(setTableView(false))}>
              <img src={showTableView ? cardIcon : cardIconWhite} alt="hierarchy format" />
            </HierarchyButton>
          </div>
          <div style={{ display: 'flex' }}>
            <Dropdown buttonContent="Region" dropdownId="region_filter">
              <FilterCheckboxGroup checkboxOptions={twdbFloodPlanningRegionIdOptions} name='region' />
            </Dropdown>
            <Dropdown buttonContent="Type" dropdownId="type_filter">
              <FilterCheckboxGroup checkboxOptions={modelTypeOptions} name='modelType' />
            </Dropdown>
            <Dropdown buttonContent="Status" dropdownId="status_filter" style={{minWidth: '160px'}}>
              <FilterCheckboxGroup checkboxOptions={statusOptions} name='status' />
            </Dropdown>
            <SearchWrapper>
              <img src={searchIcon} alt="Search magnifying glass" />
              <input className='rounded-input' id="noticesSearchInput" placeholder="Search name or identifier" onChange={e => dispatch(setSearch(e.target.value))} value={search || ""} />
            </SearchWrapper>
          </div>
        </SearchAndNew>
        {selectedPendingRecords.length > 0 && <SelectionBlurb>
          Your selection includes {selectedPendingRecords.length} pending record{selectedPendingRecords.length > 1 ? 's' : ''}. <LinkLikeButton onClick={handleAcceptRecords}>Accept all pending records in selection</LinkLikeButton>
        </SelectionBlurb>}
      </TopContent>
      {showTableView 
          ? <RecordsTable recordsQueryFinished={recordsQueryFinished} /> 
          : <RecordsHierarchy recordsQueryFinished={recordsQueryFinished} />}
    </>
  )
}

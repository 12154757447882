import React, { useState } from 'react';
import styled from 'styled-components';
import { GREY_MEDIUM_LIGHT, MAIN_CONTENT_WIDTH, PRIMARY, SLATE, SHADOW_MEDIUM } from '../constants/cssVars';
import folderIcon from '../assets/icons/folder.svg'
import { useFilteredRecords } from '../hooks/useFilteredRecords';
import { regionIdToRegionNameNumber, twdbFloodPlanningRegionIds } from '../constants/formOptions';
import { Link } from 'react-router-dom';

const HIERARCHY_PADDING = 15;

const HierarchyWrapper = styled.div`
  width: ${MAIN_CONTENT_WIDTH - (HIERARCHY_PADDING * 2)}px;
  background-color: #fff;
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  border-radius: 8px;
  box-shadow: ${SHADOW_MEDIUM};
  padding: ${HIERARCHY_PADDING}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH - (HIERARCHY_PADDING * 2)}px) {
    width: 100%;
    width: calc(100% - ${HIERARCHY_PADDING * 2}px);
  }
`

const RegionWrapper = styled.div`
  button, a {
    font-family: 'Open Sans';
    border: 0px;
    background-color: transparent;
    font-weight: 600;
    text-transform: none;
    color: ${PRIMARY};
    display: flex;
    align-items: center;
    &:hover {
      color: ${SLATE};
    }
  }
  a {
    font-weight: 500;
  }
  > div {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  > p {
    font-weight: 600;
    margin: 0px;
    margin-left: 23px;
    color: #AEAEAE;
  }
`

const ARROW_SIZE = 7;
const LilArrow = styled.div`
  width: 0; 
  height: 0; 
  border-top: ${ARROW_SIZE - 3}px solid transparent;
  border-bottom: ${ARROW_SIZE - 3}px solid transparent;
  border-left: ${ARROW_SIZE}px solid ${PRIMARY};
  margin-left: 8px;
  transform: rotateZ(${({rotated}) => rotated ? 90 : 0}deg);
`

const FolderIcon = styled.img`
  width: 20px; 
  height: 15px; 
  margin-right: 8px;
`

// Model-type category item, can be toggled to show links to records
const CategoryItem = ({records, region, category}) => {
  const [toggled, setToggled] = useState(false);

  return <RegionWrapper key={`${region}_${category}`}>
    {
      records.length > 0
        ? <button onClick={() => setToggled(!toggled)}><FolderIcon src={folderIcon} />{category} <LilArrow rotated={toggled} /></button>
        : <p>{region}</p>
    }
    {toggled && <div>
      {records.map(record => {
        return <Link to={`/app/view/${record.tdisDataIdentifier}`} key={record.tdisDataIdentifier}>{record.currentMetadata.modelName}</Link>
      })}
    </div>}
  </RegionWrapper>
}

// Region item, can be toggled to show categories, or records if they don't fit into existing categories
const RegionItem = ({region}) => {
  const records = useFilteredRecords();
  const [toggled, setToggled] = useState(false);

  const recordsUnderRegion = records.filter(record => record.currentMetadata.twdbFloodPlanningRegionID.includes(region))
  const recordsUnderRegionByCategory = recordsUnderRegion.reduce((accum, record) => {
    const recordCategories = record.currentMetadata.modelType || [];
    recordCategories.forEach(category => {
      if (!accum[category]) {
        accum[category] = [record];
      } else {
        accum[category].push(record);
      }
    })
    if (recordCategories.length < 1) {
      if (!accum['no_category_defined']) {
        accum['no_category_defined'] = [record];
      } else {
        accum['no_category_defined'].push(record);
      }
    }
    return accum;
  }, {})

  const displayName = regionIdToRegionNameNumber[region];
  return <RegionWrapper key={region}>
    {
      recordsUnderRegion.length > 0
        ? <button onClick={() => setToggled(!toggled)}><FolderIcon src={folderIcon} />{displayName} <LilArrow rotated={toggled} /></button>
        : <p style={{marginLeft: '34px'}}>{displayName}</p>
    }
    {toggled && <div>
      {Object.keys(recordsUnderRegionByCategory).sort().map(category => {
        if (category !== 'no_category_defined') {
          return <CategoryItem key={`${category}_${region}`} category={category} region={region} records={recordsUnderRegionByCategory[category]}  />
        }
      })}
      {(recordsUnderRegionByCategory['no_category_defined'] || []).map(record => {
        return <Link to={`/app/view/${record.tdisDataIdentifier}`} key={record.tdisDataIdentifier}>{record.currentMetadata.modelName}</Link>
      })}
    </div>}
  </RegionWrapper>
}

// Hierarchical view of the regions, which are parents to categories, which are parents to records  
export const RecordsHierarchy = () => {
  return <HierarchyWrapper>
    {twdbFloodPlanningRegionIds.map(region => <RegionItem key={region} region={region} />)}
  </HierarchyWrapper>
}


import React from 'react'
import styled from 'styled-components'
import { HEADER_HEIGHT, PRIMARY_DARK, GREY_LIGHT } from '../constants/cssVars'
import { Link } from 'react-router-dom'
import tdisLogo from '../assets/tdis.png'
import twdbLogo from '../assets/twdb-logo.png'
import universityLogo from '../assets/university-logo.png' // Import the university logo

const HeaderWrapper = styled.div`
  position: fixed;
  height: ${HEADER_HEIGHT}px;
  color: ${PRIMARY_DARK};
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const LogosWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px; /* Adjust margin as needed */
`

const TDISLogo = styled.img`
  width: 126px;
  height: 45px;
  padding: 0px 5px;
  background-color: white;
  border: 1px solid ${GREY_LIGHT};
  margin-right: 10px; /* Maintain gap between logos */
  @media only screen and (max-width: 700px) {
    width: 80px;
    height: 30px;
  }
`

const TWDBLogo = styled.img`
  width: 150px;
  height: 45px;
  padding-left: 6px;
  background-color: white;
  border: 1px solid ${GREY_LIGHT};
  @media only screen and (max-width: 700px) {
    width: 100px;
    height: 30px;
  }
`

const UniversityLogo = styled.img`
  width: 150px; // Adjust size as needed
  height: 45px; // Adjust size as needed
  background-color: white;
  border: 1px solid ${GREY_LIGHT};
  margin-right: 20px; /* Adjust margin as needed */
  @media only screen and (max-width: 700px) {
    width: 100px;
    height: 30px;
  }
`

const HeaderLink = styled(Link)`
  font-family: 'Open Sans Condensed', sans-serif;
  text-transform: uppercase;
  font-size: 1.2em;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  margin-right: 130px; /* Adjust margin as needed */
`

const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Logos = () => {
  return (
    <LogosWrapper>
      <TDISLogo src={tdisLogo} alt="TDIS logo" />
      <TWDBLogo src={twdbLogo} alt="Texas Water Development Board Logo" />
    </LogosWrapper>
  )
}

export const LoggedOutHeader = () => {
  return (
    <header>
      <HeaderWrapper>
        <Logos />
        <HeaderLink to="/app">MS² Admin Review Tool</HeaderLink>
        <RightSideContainer>
          <UniversityLogo src={universityLogo} alt="University Logo" />
        </RightSideContainer>
      </HeaderWrapper>
    </header>
  )
}

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { mutateAsync } from "redux-query";
import { duplicateRecordQuery, saveRecordQuery, loginQuery, resetPasswordQuery, sendMessageQuery, deleteRecordQuery, addContactQuery, updateContactQuery, acceptRecordQuery, rejectRecordQuery, updateStatusQuery, acceptRecordsQuery, getRecordQuery } from "../actions/queries";
import { USER_STORAGE_KEY } from "../constants/general";
import { statusIsGood, statusIsUnauthorized } from "../utils/helpers";
import { useHandleUnauthorized } from "./useHandleUnauthorized";
import { useCurrentUser } from '../hooks/useCurrentUser';
import { convertAppFieldsToDbProperties } from "../utils/fieldConversion";

const SUCCESS_MESSAGE = "Success!"
const ERROR_MESSAGE = "An error occurred. Please try again later."

const bodyAndStatusAreGood = (status, body) => {
  if (body && body.message && (body.message.toLowerCase().includes('error') || body.message.toLowerCase().includes('problem'))) {
    return false;
  }
  return statusIsGood(status);
}

// Hook we can reuse for simple queries
const useSimpleQuery = (query, onSuccess, successMsg, errorMsg) => {
  const [submitting, setSubmitting] = useState(false); 
  const handleUnauthorized = useHandleUnauthorized();
  const { accessToken } = useCurrentUser();
  const dispatch = useDispatch();

  const submit = (values) => {
    setSubmitting(true);
    dispatch(mutateAsync(query(values, accessToken))).then(({ status, body }) => {
      if (bodyAndStatusAreGood(status, body)) {
      // if (statusIsGood(status) && body) {
        setSubmitting(false);
        successMsg && toast.success(successMsg);
        // onSuccess && onSuccess(body);
        onSuccess && onSuccess();
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(errorMsg || ERROR_MESSAGE);
      }
    });
  }
  return [submit, submitting]
}

export const useDuplicateRecord = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const { accessToken, id, username } = useCurrentUser();
  const handleUnauthorized = useHandleUnauthorized();

  const submit = (recordId) => {
    dispatch(mutateAsync(duplicateRecordQuery(accessToken, recordId))).then(({ status, body }) => {
      if (bodyAndStatusAreGood(status, body)) {
        setSubmitting(false);
        // TODO: UPDATE THIS TO GET RECORD QUERY (not get all records) WHEN IT's AVAILABLE!!
        const newId = body && Array.isArray(body.features) && body.features.length > 0 && body.features[0].properties.tdis_identifier;
        if (newId) {
          setSubmitting(false);
          toast.success('Duplicated record!');
          history.push(`/app/form/${newId}`)
        } else {
          setSubmitting(false);
          toast.error(ERROR_MESSAGE);
        }
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(ERROR_MESSAGE);
      }
    })
  }
  return [submit, submitting];
}

export const useSaveRecord = (tdisDataIdentifier, hideSuccessMessage) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const handleUnauthorized = useHandleUnauthorized();
  const { accessToken, id } = useCurrentUser();

  const submit = async (values, origContacts, onSuccess) => {
    setSubmitting(true);
    const recordId = tdisDataIdentifier === 'new' ? null : tdisDataIdentifier;
    let transformedValues = convertAppFieldsToDbProperties({ ...values });
    if (recordId) {
      transformedValues = {...transformedValues, tdis_identifier: recordId, userId: id }
    }
    transformedValues = {...transformedValues, userId: id  }
    dispatch(mutateAsync(saveRecordQuery(transformedValues, { ...values, contacts: origContacts }, recordId, accessToken))).then(({ status, body }) => {
      if (bodyAndStatusAreGood(status, body)) {
        setSubmitting(false);
        if (!hideSuccessMessage) {
          toast.success('Saved record!');
        }
        const newId = body && Array.isArray(body.features) && body.features.length > 0 && body.features[0].properties.tdis_identifier;
        onSuccess && onSuccess(newId);
      } else if (statusIsUnauthorized(status)) {
        handleUnauthorized();
      } else {
        setSubmitting(false);
        toast.error(ERROR_MESSAGE);
      }
    });
  }
  return [submit, submitting];
}

/*
1,read-only
2,editor
3,admin-basic
4,admin-advanced
5,admin-agency
6,admin-operational
7,admin-application
*/

const acceptableRoles = ['ROLE_ADMIN-BASIC', 'ROLE_ADMIN-ADVANCED', 'ROLE_ADMIN-AGENCY', 'ROLE_ADMIN-OPERATIONAL', 'ROLE_ADMIN-APPLICATION']
const getAdminRoleMatch = (userRoles) => {
  return userRoles.some(userRole => acceptableRoles.includes(userRole));
}

export const useLoginQuery = (onSuccess) => {
  const [submitting, setSubmitting] = useState(false); 
  const dispatch = useDispatch();

  const submit = async (values) => {
    setSubmitting(true);
    dispatch(mutateAsync(loginQuery(values))).then(({ status, body }) => {
      const { roles } = body || {};
      const hasAdminRole = Array.isArray(roles) && getAdminRoleMatch(roles);
      if (statusIsGood(status) && hasAdminRole) {
        setSubmitting(false);
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify({
          ...values,
          ...body
        }));
        onSuccess();
      } else {
        setSubmitting(false);
        toast.error("Could not log in with the provided credentials")
      }
    });
  }
  return [submit, submitting]
}

export const useResetPassword = () => useSimpleQuery(resetPasswordQuery, null, "Reset password successfully!", "Failed to reset password.")
export const useSendMessageQuery = (onSuccess) => useSimpleQuery(sendMessageQuery, onSuccess, null, "Failed to send message.")
export const useDeleteRecordQuery = (onSuccess) => useSimpleQuery(deleteRecordQuery, onSuccess, "Successfully deleted record", "Failed to delete record.")
export const useAddContactQuery = (onSuccess) => useSimpleQuery(addContactQuery, onSuccess, null, "Failed to add contact.")
export const useUpdateContactQuery = (onSuccess) => useSimpleQuery(updateContactQuery, onSuccess, null, "Failed to update contact.")

// Admin only
export const useSetStatusQuery = (onSuccess) => useSimpleQuery(updateStatusQuery, onSuccess, "Record successfully updated", null)
export const useAcceptRecordsQuery = (onSuccess) => useSimpleQuery(acceptRecordsQuery, onSuccess, "Records successfully accepted", null)


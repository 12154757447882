import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useRowSelect, usePagination, useFilters, useGlobalFilter } from 'react-table'
import { recordsSelector } from '../selectors/entities';
import { GREY_MEDIUM_LIGHT, SHADOW_MEDIUM, WIDE_CONTENT_WIDTH } from '../constants/cssVars';
import { useHistory } from 'react-router';
import { Table, TableRow, TableHeader, TableData, TableFooter, TableWrapper } from './Common'
import { searchStrSelector, filterValuesSelector, selectedRecordsSelector, recordsTablePrevStateSelector } from '../selectors/general';
import { setRecordsTablePrevState, setSelectedRecords } from '../actions/general';
import { regionIdToRegionName } from '../constants/formOptions';
import { WaveLoader } from './Loaders';
import { metadataFieldInfo } from '../constants/metadataFields';
import { useLocation } from 'react-router-dom';

const OuterWrapper = styled.div`
  border: 1px solid ${GREY_MEDIUM_LIGHT};
  border-radius: 8px;
  font-size: 0.9em;
  box-shadow: ${SHADOW_MEDIUM};
  width: ${WIDE_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${WIDE_CONTENT_WIDTH}px) {
    width: 100%;
  }
`
const customRegionFilter = (rows, id, filterValue) => rows.filter((row) => filterValue[row.original.twdbFloodPlanningRegionID]);
const customStatusFilter = (rows, id, filterValue) => rows.filter((row) => filterValue[row.original.status]);
const customModelTypeFilter = (rows, id, filterValue) => rows.filter((row) => {
  return row.original.modelType && row.original.modelType.split(', ').some(type => filterValue[type]);
});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export const RecordsTable = ({ recordsQueryFinished }) => {
  const records = useSelector(recordsSelector);
  const searchStr = useSelector(searchStrSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const regionFilters = useSelector((state) => filterValuesSelector(state, 'region'))
  const statusFilters = useSelector((state) => filterValuesSelector(state, 'status'))
  const typeFilters = useSelector((state) => filterValuesSelector(state, 'modelType'))
  const selectedRecords = useSelector(selectedRecordsSelector);
  const prevTableState = useSelector(recordsTablePrevStateSelector);

  const queryParams = new URLSearchParams(location.search)
  const currentPage = queryParams.get('startOnPage') || 0;

  const data = React.useMemo(
    () => records.map(obj => ({ 
      status: obj.statusInfo.status,
      modelName: obj.currentMetadata.modelName,
      modelType: (obj.currentMetadata.modelType || []).join(', '),
      modelTypeRaw: obj.currentMetadata.modelType,
      modelIdentifier: obj.currentMetadata.modelIdentifier,
      twdbFloodPlanningRegionID: obj.currentMetadata.twdbFloodPlanningRegionID,
      twdbFloodPlanningRegionIDName: regionIdToRegionName[obj.currentMetadata.twdbFloodPlanningRegionID],
      tdisDataIdentifier: obj.tdisDataIdentifier,
    })),
    [records]
  )

  const columns = React.useMemo(() => {
    return [
      { accessor: 'modelName', Header: metadataFieldInfo['modelName'].friendlyName },
      { accessor: 'modelIdentifier', Header: metadataFieldInfo['modelIdentifier'].friendlyName },
      { accessor: 'twdbFloodPlanningRegionID', Header: metadataFieldInfo['twdbFloodPlanningRegionID'].friendlyName, filter: customRegionFilter },
      { accessor: 'twdbFloodPlanningRegionIDName', Header: 'TWDB Flood Planning Region Name' },
      { accessor: 'modelType', Header: metadataFieldInfo['modelType'].friendlyName, filter: customModelTypeFilter },
      { accessor: 'status', Header: 'Status', filter: customStatusFilter },
    ]
  }, [])

  const ourGlobalFilterFunction = useCallback(
    // This is Typescript if you're using JS remove the types (e.g. :string)
    (rows, ids, query) => {
      const strToCompare = query.toLowerCase().trim();
      return rows.filter((row) => 
          (row.values['modelName'] && row.values['modelName'].toLowerCase().includes(strToCompare)) ||
          (row.values['modelIdentifier'] && row.values['modelIdentifier'].toLowerCase().includes(strToCompare))
      );
    },
    [],
);
    
  const props = useTable( { columns, data, initialState: { pageSize: 7, pageIndex: parseInt(currentPage) }, globalFilter: ourGlobalFilterFunction }, useFilters, useGlobalFilter, usePagination, useRowSelect, hooks => {
    hooks.visibleColumns.push(columns => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      ...columns,
    ])});
  const { 
    getTableProps, getTableBodyProps, headerGroups, prepareRow, page, canPreviousPage, setFilter, setGlobalFilter,
    canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, state, selectedFlatRows,
  } = props;

  // Save the current page num in redux state
  useEffect(() => {
    if (prevTableState.pageIndex !== state.pageIndex) {
      dispatch(setRecordsTablePrevState({ pageIndex: state.pageIndex }))
    }
  }, [state.pageIndex])

  // Set table filters from redux filter state (from components outside the table, e.g. searchbar & filters dropdown) 
  useEffect(() => {
    setGlobalFilter(searchStr);
  }, [searchStr])

  useEffect(() => {
    setFilter("twdbFloodPlanningRegionID", regionFilters);
  }, [regionFilters]);

  useEffect(() => {
    setFilter("status", statusFilters);
  }, [statusFilters]);

  useEffect(() => {
    setFilter("modelType", typeFilters);
  }, [typeFilters]);


  useEffect(() => {
    // Todo: super hacky
    if (selectedFlatRows.length != selectedRecords.length) {
      dispatch(setSelectedRecords(selectedFlatRows.map((row) => row.original)))
    }
  }, [selectedFlatRows])
  
  return (
    <OuterWrapper>
      <TableWrapper>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => (
                  <TableHeader {...column.getHeaderProps()} style={{ textAlign: idx > 0 ? 'center' : 'left', width: idx > 0 ? `${100/headerGroup.headers.length}%` : '20px', maxWidth: '150px' }}>
                    {column.render("Header")}
                  </TableHeader>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} clickable={true}>
                  {row.cells.map((cell, idx) => {
                    return (
                      <TableData onClick={() => idx === 0 ? null : history.push(`/app/view/${row.original.tdisDataIdentifier}`)} style={{ textAlign: idx > 0 ? 'center' : 'left', maxWidth: '150px' }} highlighted={row.original.dataIsAvailable} {...cell.getCellProps()}>
                        <div style={{ whiteSpace: 'nowrap', textAlign: (idx + 1) === row.cells.length ? 'center' : 'left'}}>
                          {cell.render('Cell')}
                        </div>
                      </TableData>
                    );
                  })}
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>
      {!recordsQueryFinished && <div style={{ minHeight: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <WaveLoader />
      </div>}
      {recordsQueryFinished && page.length < 1 &&
          <div style={{padding: '20px', textAlign: 'center'}}>No results found matching your search query.</div> }
      <TableFooter>
        <div>
          <div>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"first"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <span style={{margin: '0px 10px'}}>
              {" "}<strong>{state.pageIndex + 1}</strong> of {pageOptions.length}{" "}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {"last"}
            </button>{" "}
          </div>
        </div>
      </TableFooter>
    </OuterWrapper>
  );
}
import { createSelector } from "reselect";

export const examplesSelector = (state) => state.entities.examples || [];
export const recordsSelector = state => state.entities.records || [];
export const messagesByIdSelector = state => state.entities.messagesById || {};
export const historyByIdSelector = state => state.entities.recordHistoryById || {};
export const fileStructureByIdSelector = state => state.entities.fileStructureById || {};
export const sessionExpirationTimeSelector = (state) => state.entities.sessionExpirationTime;
export const notificationsSelector = (state) => state.entities.notifications || [];
export const uploadConflictsSelector = (state) => state.entities.uploadConflicts || [];
export const softwareListSelector = (state) => state.entities.softwareList || [];
export const contactsSelector = (state) => state.entities.contacts || [];
export const queriedUserSelector = (state) => state.entities.user;
export const agenciesSelector = (state) => state.entities.agencies || [];
export const contactRolesSelector = (state) => state.entities.contactRoles || [];
export const contactsByIdSelector = (state) => state.entities.contactsById || {};
export const filePreviewTextByUrlSelector = (state) => state.entities.filePreviewTextByUrl || {};
export const notificationsByIdSelector = (state) => state.entities.notificationsById || [];
export const archivesByIdSelector = (state) => state.entities.archivesById || {};

const secondParamSelector = (state, param2) => param2

export const recordByIdSelector = createSelector(
  [recordsSelector, secondParamSelector],
  (objs, tdisDataIdentifier) => objs.find(obj => obj.tdisDataIdentifier === tdisDataIdentifier)
);

export const fileStructureSelector = createSelector(
  [fileStructureByIdSelector, secondParamSelector],
  (fileStructuresById, tdisDataIdentifier) => fileStructuresById[tdisDataIdentifier] || {}
);

export const recordHistorySelector = createSelector(
  [historyByIdSelector, secondParamSelector],
  (historyById, tdisDataIdentifier) => historyById[tdisDataIdentifier] || []
);

export const archivesSelector = createSelector(
  [archivesByIdSelector, secondParamSelector],
  (archivesById, tdisDataIdentifier) => archivesById[tdisDataIdentifier] || []
);

export const filteredContactRolesSelector = createSelector(
  [contactRolesSelector],
  // "Digital Object Creator" is an originator role & can only be used once; for the originator section.
  (roles) => roles.filter(role => role && role.name && role.name.toLowerCase() !== "Digital Object Creator".toLowerCase())
);

export const originatorRoleIdSelector = createSelector(
  [contactRolesSelector],
  (roles) => {
    const originatorRole = roles.find(role => role && role.name && role.name.toLowerCase() === "Digital Object Creator".toLowerCase());
    if (originatorRole) {
      return originatorRole.id;
    }
    // TODO: MaKE SURE THIS ISN'T BEING RETURNED RIGHT NOW!
    return 9;
  }
);

import { createSelector } from 'reselect';

export const activeDropdownSelector = state => state.general.activeDropdown;
export const showTableViewSelector = state => state.general.showTableView;
export const searchStrSelector = state => state.general.searchStr;
export const selectedRecordIdSelector = state => state.general.selectedRecordId;
export const selectedAgencyIdSelector = (state) => state.general.selectedAgencyId;
export const filtersSelector = state => state.general.filters;
export const selectedRecordsSelector = state => state.general.selectedRecords;
export const contactsByRecordIdSelector = (state) => state.general.contactsByRecordId;
export const contactRolesByRecordIdSelector = (state) => state.general.contactRolesByRecordId;
export const originatorContactsByRecordIdSelector = (state) => state.general.originatorContactsByRecordId || {};
export const recordsTablePrevStateSelector = (state) => state.general.recordsTablePrevState || {};

const getSecondParam = (state, param2) => param2

export const filterValuesSelector = createSelector(
  [filtersSelector, getSecondParam],
  (allFilters, filterName) => allFilters[filterName]
);